<template>
  <div class="detail-page">
    <div class="detail-title">
      {{ info.title }}
    </div>
    <div class="detail-intro">
      <div class="detail-time">{{ info.publish_date }}</div>
      <div class="detail-view">浏览量 {{ info.flowrate }}</div>
    </div>
    <div class="news-list-wrap news-detail-box">
      <div id="container" class="video-wrapper">
        <div id="vidro-content" v-show="info.vid"></div>
      </div>
      <div v-html="info.content"></div>
    </div>
    <div class="detail-share">
      <img
        src="@/assets/images/icon-share.svg"
        class="icon-share"
        @click="toShare"
      />
      分享
    </div>
    <button @click="toback" class="btn-back">
    <img src="@/assets/images/icon-home.svg">
    </button>
    <van-dialog
      v-model="showQrcode"
      :show-confirm-button="false"
      close-on-click-overlay
      title=""
    >
      <div class="qrcode-container">
        <img :src="qrcodeSrc" alt="二维码图片" />
        <div class="share-title">分享功能操作说明</div>
        <div class="share-desc">第一步：点击长按识别二维码</div>
        <div class="share-desc">第二步：点击页面右上角“...”，分享文章内容</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { detail, ticket } from "@/network/API.js";
import { Base64 } from "js-base64";
import wx from "weixin-js-sdk";
import QRCode from "qrcode";
import "../utils/txplayer.js";

export default {
  components: {},
  data() {
    return {
      info: {},
      showQrcode: false,
      qrcodeSrc: "",
      canShare: this.isWX() == false,
      player: null,
    };
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
  async created() {
    this.qrcodeSrc = await QRCode.toDataURL(
      window.location.origin + window.location.pathname
    );
    if (this.$route.params.aid) {
      let data = await detail(this.$route.params.aid);
      data.content = Base64.decode(data.content);
      this.info = data;
      this.shareInit();
      if (this.info.vid) {
        this.playerInit(this.info.vid);
      }
    }
  },
  methods: {
    toback() {
      this.$router.replace("/");
    },
    async shareInit() {
      let res = await ticket({
        url: window.location.origin + window.location.pathname,
      });
      let that = this;
      wx.config({
        debug: false,
        nonceStr: res.noncestr,
        appId: "wx3f9f67027db9ffaf",
        signature: res.signature,
        timestamp: res.timestamp,
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareAppMessage",
          "onMenuShareTimeline",
        ],
      });
      wx.checkJsApi({
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareAppMessage",
          "onMenuShareTimeline",
        ],
        success: (res) => {},
        fail: (err) => {},
      });
      wx.ready(() => {
        // this.canShare = true;
        wx.updateAppMessageShareData({
          title: that.info.title,
          desc: "",
          link: window.location.origin + window.location.pathname,
          imgUrl: that.info.cover,
          success: (res) => {
            // alert("update success");
          },
          fail: (err) => {},
        });
        wx.updateTimelineShareData({
          title: that.info.title,
          desc: "",
          link: window.location.origin + window.location.pathname,
          imgUrl: that.info.cover,
          success: (res) => {
            // alert("update success");
          },
          fail: (err) => {},
        });
      });
      wx.error((err) => {});
    },
    playerInit(vid) {
      // eslint-disable-next-line no-undef
      this.player = new Txplayer({
        containerId: "container",
        vid: vid,
        width: "100%",
        height: "250",
        autoplay: true,
        autoPlayInMute: false,
      });
      this.player.setVolume(50);
    },
    toShare() {
      this.showQrcode = !this.showQrcode;
      // let flag = this.copyText(
      //   window.location.origin + window.location.pathname
      // );
      // if (flag) {
      //   Toast("链接已复制, 请前往微信分享给好友");
      // }
    },
    isWX() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    copyText(text) {
      var textarea = document.createElement("input"); //创建input对象
      var currentFocus = document.activeElement; //当前获得焦点的元素
      document.body.appendChild(textarea); //添加元素
      textarea.value = text;
      textarea.focus();
      if (textarea.setSelectionRange)
        textarea.setSelectionRange(0, textarea.value.length);
      //获取光标起始位置到结束位置
      else textarea.select();
      let flag = "";
      try {
        flag = document.execCommand("copy"); //执行复制
      } catch (eo) {
        flag = false;
      }
      document.body.removeChild(textarea); //删除元素
      currentFocus.focus();
      return flag;
    },
  },
};
</script>

<style></style>
